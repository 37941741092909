<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="rankingClubs" title="Ranking de Clubes" :subtitle="subtitle">
      <template slot="options">
        <div>
          <restrict-access slug="rankingClubes.newColumn">
            <b-button
              :expanded="isMobile"
              type="is-independence"
              @click="openNewModule"
              >Criar novo Ranking</b-button
            >
          </restrict-access>
        </div>
      </template>

      <section class="group-stats">
        <div class="columns is-multiline">
          <div class="content">
            <!-- Conteudo do grupo -->
            <div
              class="column is-full is-centered"
              :style="{
                'padding-left': $mq == 'sm' ? 0 : '0.75rem',
                'padding-right': $mq == 'sm' ? 0 : '0.75rem',
              }"
            >
              <div v-if="showContent" class="stats-content">
                <container
                  @drop="onDrop($event)"
                  :get-child-payload="getChildPayload"
                  :should-accept-drop="shouldAcceptDrop"
                  group-name="stats"
                  orientation="horizontal"
                  :non-drag-area-selector="'.col-content'"
                >
                  <draggable
                    v-for="(card, idCard) of arrColumns"
                    :key="'drag-' + idCard"
                    :id="'drag-' + idCard"
                  >
                    <div
                      v-if="loadCards[idCard]"
                      class="block"
                      :style="{ width: 'min-content' }"
                    >
                      <!-- Buttons (Close and Configure) -->
                      <div class="align-button-block">
                        <b-button
                          @click="deleteColumn(idCard)"
                          type="is-danger"
                          icon-pack="fas"
                          size="is-small"
                          icon-left="times"
                          rounded
                        ></b-button>
                      </div>
                      <div class="align-button-title">
                        <restrict-access slug="rankingClubes.configColumn">
                          <b-button
                            @click="toggleConfig(idCard)"
                            type="is-primary"
                            size="is-small"
                            icon-pack="fas"
                            icon-left="sliders-h"
                            rounded
                          ></b-button>
                        </restrict-access>
                      </div>

                      <!--  Title and Subtitle -->
                      <div class="info-title">
                        <h4 class="h4-title">{{ card.title }}</h4>
                        <h4
                          class="h4-subtitle"
                          :style="{
                            'background-color': colorMando(card.mando),
                          }"
                        >
                          {{ card.scout.sigla }} - {{ card.filtro }} -
                          {{
                            (card.mando === "casa"
                              ? "Mandante"
                              : card.mando === "fora"
                              ? "Visitante"
                              : "TOTAL") | capitalize
                          }}
                        </h4>
                      </div>

                      <!-- Config para editar a coluna -->
                      <div
                        v-show="card.config"
                        :key="'coluna_' + idCard"
                        :id="'coluna_' + idCard"
                        class="info-row"
                        style="z-index: 9999; position: relative"
                      >
                        <div class="config">
                          <b-field
                            label="Scout"
                            :type="
                              error && typeof card.scout !== 'object'
                                ? 'is-danger'
                                : 'is-light'
                            "
                            :message="
                              error && typeof card.scout !== 'object'
                                ? 'Escolha um Scout'
                                : ''
                            "
                          >
                            <b-select v-model="card.scout" expanded>
                              <option
                                v-for="(opt, index) in arr_scouts_kpi"
                                :key="index"
                                :value="opt"
                                >{{ opt.nome }}</option
                              >
                            </b-select>
                          </b-field>
                          <b-field
                            label="Mando de Jogo"
                            style="margin-top: 20px;"
                            :type="
                              error && card.mando == ''
                                ? 'is-danger'
                                : 'is-light'
                            "
                            :message="
                              error && card.mando == ''
                                ? 'Escolha o Mando de Jogo'
                                : ''
                            "
                          >
                            <div>
                              <b-switch
                                v-model="card.mando"
                                true-value="casa"
                                :name="'mando-' + idCard"
                                >Mandante</b-switch
                              >
                              <b-switch
                                v-model="card.mando"
                                true-value="fora"
                                :name="'mando-' + idCard"
                                >Visitante</b-switch
                              >
                              <b-switch
                                v-model="card.mando"
                                true-value="ambos"
                                :name="'mando-' + idCard"
                                >Ambos</b-switch
                              >
                            </div>
                          </b-field>
                          <b-field
                            label="Filtro"
                            style="margin-top: 20px;"
                            :type="
                              error && card.filtro == ''
                                ? 'is-danger'
                                : 'is-light'
                            "
                            :message="
                              error && card.filtro == ''
                                ? 'Escolha o Filtro'
                                : ''
                            "
                          >
                            <b-select v-model="card.filtro" expanded>
                              <option
                                v-for="opt in arr_filters"
                                :key="opt"
                                :value="opt"
                                >{{ opt }}</option
                              >
                            </b-select>
                          </b-field>
                          <b-field
                            style="margin-top: 25px;"
                            :message="
                              loadingSaveConfig
                                ? 'Salvando e Atualizando Dados...'
                                : ''
                            "
                          >
                            <b-button
                              @click="saveColumn(idCard)"
                              type="is-primary"
                              :loading="loadingSaveConfig"
                              >Atualizar</b-button
                            >
                          </b-field>
                        </div>
                      </div>

                      <!-- Conteudo de cada coluna -->
                      <div class="col-content">
                        <b-table
                          :key="
                            'table-' +
                              idCard +
                              card.scout.sigla +
                              card.mando +
                              card.filtro
                          "
                          :id="
                            'table-' +
                              idCard +
                              card.scout.sigla +
                              card.mando +
                              card.filtro
                          "
                          :data="card.data"
                          :mobile-cards="false"
                          ref="table"
                          :detailed="card.tipo === 'scout'"
                          detail-key="ranking"
                          @details-open="
                            (row, a) => ativeAcord(idCard, row.ranking - 1)
                          "
                          :show-detail-icon="true"
                          narrowed
                          :sticky-header="true"
                          height="55vh"
                          v-if="loadCardTable[idCard]"
                        >
                          <template slot-scope="props" slot="header">
                            <div
                              v-if="props.column.sortable"
                              class="label-table centralize-row"
                              @click="statusSort(idCard, props.index)"
                              v-tooltip.bottom="{
                                content: props.column.meta,
                                classes: 'home-tooltip',
                              }"
                            >
                              <span
                                v-if="
                                  props.column.sortable &&
                                    showVisibleSort[idCard][props.index]
                                "
                              >
                                <b-icon
                                  icon="sort"
                                  pack="fas"
                                  size="is-small"
                                ></b-icon>
                              </span>
                              <i
                                v-if="props.column.meta"
                                class="fas fa-question-circle"
                                style="margin-right: 2px;"
                              ></i>
                              <p>{{ props.column.label }}</p>
                            </div>
                            <div v-else class="label-table centralize-row">
                              <i
                                v-if="props.column.meta"
                                class="fas fa-question-circle"
                                style="margin-right: 2px;"
                              ></i>
                              <p>{{ props.column.label }}</p>
                            </div>
                          </template>

                          <template slot-scope="props">
                            <b-table-column
                              field="mando"
                              label="Mando Atual"
                              centered
                            >
                              <b-tag
                                :type="
                                  props.row.mando == 'casa'
                                    ? 'is-casa'
                                    : 'is-fora'
                                "
                                rounded
                                >{{
                                  props.row.mando.substr(0, 1).toUpperCase()
                                }}</b-tag
                              >
                            </b-table-column>

                            <b-table-column
                              @click="toggle(props.row)"
                              field="time_sigla"
                              label="Clubes"
                              centered
                              sortable
                            >
                              {{ props.row.time_sigla }}
                            </b-table-column>

                            <b-table-column
                              @click="toggle(props.row)"
                              v-if="card.tipo == 'scout'"
                              field="scout"
                              :label="card.scout.sigla"
                              centered
                              sortable
                            >
                              <p v-if="card.tipo == 'scout'">
                                {{ props.row.scout }}
                              </p>
                            </b-table-column>

                            <b-table-column
                              @click="toggle(props.row)"
                              v-if="card.tipo == 'scout'"
                              field="jogos"
                              label="Jogos"
                              centered
                              sortable
                            >
                              <p v-if="card.tipo == 'scout'">
                                {{ props.row.jogos }}
                              </p>
                            </b-table-column>

                            <b-table-column
                              @click="toggle(props.row)"
                              v-if="card.tipo == 'scout'"
                              field="media"
                              label="Média"
                              centered
                              sortable
                            >
                              <p v-if="card.tipo == 'scout'">
                                {{ props.row.media.toFixed(2) }}
                              </p>
                            </b-table-column>

                            <!-- KPI -->
                            <b-table-column
                              v-for="(kpi, a) of card.kpis"
                              :key="a"
                              :field="kpi.sigla"
                              :label="kpi.sigla"
                              :meta="kpi.nome"
                              centered
                              sortable
                            >
                              {{
                                kpi.formato.indexOf("%") !== -1
                                  ? (props.row[kpi.sigla] * 100).toFixed(
                                      kpi.formato.replace("%", "")
                                    ) + "%"
                                  : props.row[kpi.sigla].toFixed(kpi.formato)
                              }}
                            </b-table-column>
                          </template>

                          <template slot="detail" slot-scope="props">
                            <table v-if="renderTableDetail">
                              <thead>
                                <th
                                  style="text-align: left; padding-left: 5px;"
                                >
                                  <!-- Posição -->
                                </th>
                                <th style="text-align: left;">Nome</th>
                                <th
                                  class="has-text-centered"
                                  v-tooltip.bottom="{
                                    content: card.scout.nome,
                                    classes: 'home-tooltip',
                                  }"
                                >
                                  {{ card.scout.sigla }}
                                </th>
                                <th class="has-text-centered">Média</th>
                                <th
                                  class="has-text-centered"
                                  v-tooltip.bottom="{
                                    content: 'Participação nos jogos do clube',
                                    classes: 'home-tooltip',
                                  }"
                                >
                                  Jogos
                                </th>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(data, index) of props.row.dataAcord"
                                  :key="index"
                                >
                                  <td
                                    style="text-transform: uppercase; font-weight:bolder; text-align: left; font-size: 0.6rem;"
                                  >
                                    <!-- <i class="fas fa-circle" :style="{ color: colorMando(data.atleta_pos) }" style="padding: 0 5px 0 5px;"></i> -->
                                    {{ data.atleta_pos }}
                                  </td>
                                  <td
                                    class="td-acord"
                                    style="text-align: left;"
                                  >
                                    {{ data.atleta_nome }}
                                  </td>
                                  <td class="td-acord has-text-centered">
                                    {{ data.scout }} ({{
                                      (
                                        (data.scout / props.row.scout) *
                                        100
                                      ).toFixed(1)
                                    }}%)
                                  </td>
                                  <td class="td-acord has-text-centered">
                                    {{
                                      (
                                        data.scout / data.partidas_atleta
                                      ).toFixed(1)
                                    }}
                                  </td>
                                  <td class="td-acord has-text-centered">
                                    {{ data.partidas_atleta }}/{{
                                      data.time_partidas
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div v-else>
                              <div class="section">
                                <div
                                  class="content has-text-centered"
                                  style="font-size: 0.9rem;"
                                >
                                  <p>
                                    <b-icon
                                      pack="fas"
                                      icon="spinner"
                                      size="is-medium"
                                    >
                                    </b-icon>
                                  </p>
                                  <p>Carregando...</p>
                                </div>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </draggable>
                </container>
              </div>
            </div>
          </div>
        </div>

        <!-- Nova Coluna -->
        <b-modal :active.sync="showVodal" has-modal-card>
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Nova Coluna</p>
            </header>
            <section class="modal-card-body">
              <b-field
                label="Scout"
                :type="
                  error && typeof column.scout !== 'object'
                    ? 'is-danger'
                    : 'is-light'
                "
                :message="
                  error && typeof column.scout !== 'object'
                    ? 'Escolha um Scout'
                    : ''
                "
              >
                <b-select v-model="column.scout" expanded>
                  <option
                    v-for="(opt, index) in arr_scouts_kpi"
                    :key="index"
                    :value="opt"
                    >{{ opt.nome }}</option
                  >
                </b-select>
              </b-field>

              <b-field
                label="Mando de Jogo"
                :type="error && column.mando == '' ? 'is-danger' : 'is-light'"
                :message="
                  error && column.mando == '' ? 'Escolha o Mando de Jogo' : ''
                "
              >
                <div>
                  <b-switch
                    v-model="column.mando"
                    true-value="casa"
                    name="mando-vodal"
                    >Mandante</b-switch
                  >
                  <b-switch
                    v-model="column.mando"
                    true-value="fora"
                    name="mando-vodal"
                    >Visitante</b-switch
                  >
                  <b-switch
                    v-model="column.mando"
                    true-value="ambos"
                    name="mando-vodal"
                    >Ambos</b-switch
                  >
                </div>
              </b-field>

              <b-field
                label="Filtro"
                :type="error && column.filtro == '' ? 'is-danger' : 'is-light'"
                :message="
                  error && column.filtro == '' ? 'Escolha o Filtro' : ''
                "
              >
                <b-select v-model="column.filtro" expanded>
                  <option v-for="opt in arr_filters" :key="opt" :value="opt">{{
                    opt
                  }}</option>
                </b-select>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button @click="showVodal = false" type="is-danger" outlined
                >Cancelar</b-button
              >
              <button @click="addColumn()" class="button is-success">
                Adicionar
              </button>
            </footer>
          </div>
        </b-modal>
      </section>
    </BasePage>
  </BaseLayout>
</template>

<script>
import { mapGetters } from "vuex";

import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";

import { Container, Draggable } from "vue-smooth-dnd";
import PainelVodal from "@/components/helpers/vodal/PainelVodal";
import restrictAccess from "@/components/restrictAccess/restrictAccess";

import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

export default {
  name: "rankingClubs",
  mixins: [championshipMixins],
  components: {
    container: Container,
    draggable: Draggable,
    "p-vodal": PainelVodal,
    "restrict-access": restrictAccess,
    BaseLayout,
    BasePage,
  },
  data() {
    return {
      // arrColumns: [
      //   {config: false, filtro: "3 jogos", mando: 'fora', scout: { nome: 'Gols', sigla: 'G' }, title: 'Ranking Gols'},
      //   {config: false, filtro: "5 jogos", mando: 'casa', scout: { nome: 'Gols', sigla: 'G' }, title: 'Ranking Gols'},
      //   {config: false, filtro: "todos", mando: 'fora', scout: { nome: 'Gols', sigla: 'G' }, title: 'Ranking Gols'},
      // ],
      arrColumns: [],
      arr_scouts_kpi: [],
      column: {
        mando: "",
        filtro: "3 jogos",
      },
      showContent: true,
      showVodal: false,
      error: false,
      errorCards: false,
      // arr_filters: ['3 jogos', '5 jogos', '1 turno', '2 turno', 'todos'],
      arr_filters: ["3 jogos", "5 jogos", "1 turno", "todos"],
      loadingSaveConfig: false,
      loadCards: [],
      loadCardTable: [],
      showVisibleSort: [],
      renderTableDetail: false,
      max_columns: false,
      deleteId: null,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    subtitle() {
      return "Rankings de clubes personalizados por scouts e outros indicadores específicos";
    },
  },
  async created() {
    let loader = this.$loading.show();
    let userId = this.userId;
    let nm_conf = "ordem_ranking_clubes";

    await this.loadChampionships();
    this.setDefaultToBrazilChampionship();

    await Promise.all([
      this.$http.get(url + `/clubes/rnkClubes/scoutKPI`),
      this.$http.get(url + "/usuarios/" + userId + "/configs/" + nm_conf),
    ])
      .catch((err) => console.log(err))
      .then((res) => {
        this.arr_scouts_kpi = res[0].data.data;

        let _data = JSON.parse(res[1].data.data[0].valor);

        if (_data.length === 1) {
          _data = _data[0];
        }

        this.defaultcol = JSON.parse(res[1].data.data[0].valor_padrao);
        // if (_data.length == 0) {
        //   _data = this.defaultcol
        //   _data = _data[0]
        // }

        for (let card of _data) {
          let tipoScout = "";
          for (const scout_kpi of this.arr_scouts_kpi) {
            if (scout_kpi.sigla === card.scout.sigla) {
              tipoScout = scout_kpi.tipo;
            }
          }

          let filtro =
            card.filto != "todos" ? card.filtro.replace(" ", "%") : card.filtro;

          card.data = [];
          this.$http
            .get(
              url +
                `/clubes/rnkClubes/scoutClubes?tipoScout=${tipoScout}&scout=${card.scout.sigla}&mando=${card.mando}&filtro=${filtro}`
            )
            .catch((err) => console.error("ERROR_HTTP"))
            .then((res) => {
              card.tipo = res.data.data.tipo;
              card.kpis = res.data.data.kpis;
              card.data = res.data.data.ranking;

              this.loadCards.push(true);
              this.loadCardTable.push(true);

              let visible = [true, true, true, true, true];
              if (card.kpis) {
                for (const kpi of card.kpis) {
                  visible.push(true);
                }
              }
              this.showVisibleSort.push(visible);
            });
          this.arrColumns.push(card);
          loader.hide();
        }
      });
  },
  methods: {
    statusSort(card, index) {
      this.showVisibleSort[card] = this.showVisibleSort[card].map(
        (el, index) => true
      );
      this.showVisibleSort[card][index] = false;
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    ativeAcord(idCard, idData) {
      this.renderTableDetail = false;

      let mando = this.arrColumns[idCard].mando;
      let filtro = this.arrColumns[idCard].filtro;
      let scout = this.arrColumns[idCard].scout.sigla;
      let idClube = this.arrColumns[idCard].data[idData].time_codigo;

      filtro = filtro.replace(" ", "%");

      if (!this.arrColumns[idCard].data[idData].dataAcord) {
        this.$http
          .get(
            url +
              `/clubes/rnkClubes/scoutClubes/atletas?scout=${scout}&mando=${mando}&filtro=${filtro}&idClube=${idClube}`
          )
          .catch((err) => console.error("ERROR_HTTP"))
          .then((res) => {
            this.arrColumns[idCard].data[idData].dataAcord = res.data.data.sort(
              (a, b) => {
                if (a.scout < b.scout) return 1;
                else if (a.scout > b.scout) return -1;
                else return 0;
              }
            );
            this.renderTableDetail = true;
          });
      } else {
        this.renderTableDetail = true;
      }
    },
    openNewModule(idPosicao) {
      this.error = false;
      this.showVodal = true;
      this.arrColumns.map((el) => {
        el.config = false;
        return el;
      });
    },
    hideVodal() {
      this.column = { mando: "", filtro: "3 jogos" };
      this.showVodal = false;
    },
    verifyColumn() {
      if (this.arrColumns.length < 5) {
        if (this.column.mando == "") return false;
        if (this.column.filtro == "") return false;
        if (typeof this.column.scout != "object") return false;
        else return true;
      } else {
        this.max_columns = true;
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Limite de colunas atingido! (máx. 5)",
          type: "is-warning",
          hasIcon: true,
        });
        this.hideVodal();
        return false;
      }
    },
    async addColumn() {
      this.error = false;

      if (this.verifyColumn()) {
        if (this.error) this.error = false;
        this.column.config = false;

        let loader = this.$loading.show();

        let tipoScout = "";
        for (const scout_kpi of this.arr_scouts_kpi) {
          if (scout_kpi.sigla === this.column.scout.sigla) {
            tipoScout = scout_kpi.tipo;
          }
        }

        let _configs = {
          mando: this.column.mando,
          tipoScout: tipoScout,
          scout: this.column.scout.sigla,
          filtro: this.column.filtro,
        };

        this.column.title = "Ranking " + this.column.scout.nome;

        this.$http
          .get(
            url +
              `/clubes/rnkClubes/scoutClubes?tipoScout=${tipoScout}&scout=${_configs.scout}&mando=${_configs.mando}&filtro=${_configs.filtro}`
          )
          .catch((err) => console.error("ERROR_HTTP"))
          .then((res) => {
            this.showContent = false;

            this.column.tipo = res.data.data.tipo;
            this.column.kpis = res.data.data.kpis;
            this.column.data = res.data.data.ranking;

            this.column.sortConq = "";
            this.column.sortCed = "";

            let el = this.column;
            let group = el.group;
            delete el.group;

            this.loadCards.push(true);
            this.loadCardTable.push(true);
            this.arrColumns.push(el);

            this.showVisibleSort = this.showVisibleSort
              .map((el) => el)
              .map((elm, index) => [true, true, true, true, true, true, true]);

            let visible = [true, true, true, true, true];
            if (el.kpis) {
              for (const kpi of el.kpis) {
                visible.push(true);
              }
            }
            this.showVisibleSort.push(visible);

            this.hideVodal();
            this.setModules(true);
            this.showContent = true;
            loader.hide();
          });
      } else {
        this.error = this.max_columns ? false : true;
        this.max_columns = false;
      }
    },
    toggleConfig(id) {
      this.arrColumns[id].config = !this.arrColumns[id].config;
      let oColumnCard = document.getElementById("coluna_" + id);
      if (this.arrColumns[id].config) oColumnCard.style.display = "";
      if (!this.arrColumns[id].config) oColumnCard.style.display = "none";
    },
    async deleteColumn(id) {
      this.deleteId = id;
      this.loadCardTable = this.loadCardTable.map((el, i) => i < id);

      // let title = "<p>"+this.arrColumns[id].title +"<br>"+
      //             (this.arrColumns[id].scout.sigla +" - "+
      //             this.arrColumns[id].filtro +" - "+
      //             ((this.arrColumns[id].mando === "casa") ?
      //               "Mandante" :
      //               ((this.arrColumns[id].mando === "fora") ?
      //                 "Visitante" :
      //                 "TOTAL"))).toUpperCase()+"</p>"

      // this.$buefy.dialog.confirm({
      //   title: "ATENÇÃO!",
      //   message: `Deseja realmente excluir a coluna ${title}?`,
      //   type: "is-warning",
      //   hasIcon: true,
      //   onCancel: async () => {
      //     this.loadCardTable = this.loadCardTable.map(el => true)
      //   },
      //   onConfirm: async () => {

      this.showContent = false;
      this.showVisibleSort = [];

      this.arrColumns.splice(this.deleteId, 1);
      this.loadCardTable.splice(this.deleteId, 1);
      this.loadCards.splice(this.deleteId, 1);

      this.deleteId = null;

      this.showVisibleSort = this.arrColumns.map((el) => {
        let visible = [true, true, true, true, true, true, true];
        if (el.kpis) {
          for (const kpi of el.kpis) {
            visible.push(true);
          }
        }
        return visible;
      });

      this.loadCardTable = this.loadCardTable.map((el) => true);
      this.loadCards = this.loadCards.map((el) => true);
      this.showContent = true;

      await this.setModules();
      await this.$forceUpdate();
      //   }
      // });
    },
    ordColumn(id, ord) {
      if (ord == "ced") {
        let sort = this.arrColumns[id].sortCed;

        if (sort === "") sort = "down";
        else if (sort === "down") sort = "up";
        else if (sort === "up") sort = "down";

        this.arrColumns[id].sortCed = sort;
        this.arrColumns[id].data = this.arrColumns[id].data.sort(function(
          a,
          b
        ) {
          b.type.ced - a.type.ced;
          if (sort === "down") {
            if (a.type.ced > b.type.ced) return 1;
            else if (a.type.ced < b.type.ced) return -1;
            else return 0;
          } else if (sort === "up") {
            if (a.type.ced < b.type.ced) return 1;
            else if (a.type.ced > b.type.ced) return -1;
            else return 0;
          }
        });
      }
      if (ord == "con") {
        let sort = this.columns[id].sortConq;

        if (sort === "") sort = "down";
        else if (sort === "down") sort = "up";
        else if (sort === "up") sort = "down";

        this.arrColumns[id].sortConq = sort;
        this.arrColumns[id].data = this.arrColumns[id].data.sort(function(
          a,
          b
        ) {
          b.type.con - a.type.con;
          if (sort === "down") {
            if (a.type.con > b.type.con) return 1;
            else if (a.type.con < b.type.con) return -1;
            else return 0;
          } else if (sort === "up") {
            if (a.type.con < b.type.con) return 1;
            else if (a.type.con > b.type.con) return -1;
            else return 0;
          }
        });
      }
    },
    resetColumns() {
      this.arrColumns = [];

      for (let el of this.defaultcol) {
        let tipoScout = "";
        for (const scout_kpi of this.arr_scouts_kpi) {
          if (scout_kpi.sigla === el.scout.sigla) {
            tipoScout = scout_kpi.tipo;
          }
        }

        let _configs = {
          mando: el.mando,
          tipoScout: tipoScout,
          scout: el.scout.sigla,
          filtro: el.filtro,
        };

        el.title = "Ranking " + el.scout.nome;

        this.$http
          .get(
            url +
              `/clubes/rnkClubes/scoutClubes?tipoScout=${tipoScout}&scout=${_configs.scout}&mando=${_configs.mando}&filtro=${_configs.filtro}`
          )
          .catch((err) => console.error("HTTP_ERROR"))
          .then((res) => {
            el.tipo = res.data.data.tipo;
            el.kpis = res.data.data.kpis;
            el.data = res.data.data.ranking;
            el.sortConq = "";
            el.sortCed = "";

            this.arrColumns.push(el);
          });
      }

      this.setModules();
    },
    shouldAcceptDrop(sourceContainerOptions, payload) {
      return true;
    },
    getChildPayload(index) {
      return this.arrColumns[index];
    },
    onDrop(dropResult) {
      this.arrColumns = this.applyDrag(this.arrColumns, dropResult);
      this.setModules();
    },
    applyDrag(arr, dropResult) {
      const { removedIndex, addedIndex, payload } = dropResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    verifyCard(id) {
      if (this.arrColumns[id].mando == "") return false;
      if (this.arrColumns[id].filtro == "") return false;
      if (typeof this.arrColumns[id].scout != "object") return false;
      else return true;
    },
    saveColumn(id) {
      this.loadCardTable[id] = false;
      this.error = false;

      if (this.verifyCard(id)) {
        if (this.error) this.error = false;
        this.loadingSaveConfig = true;

        let tipoScout = "";
        for (const scout_kpi of this.arr_scouts_kpi) {
          if (scout_kpi.sigla === this.arrColumns[id].scout.sigla) {
            tipoScout = scout_kpi.tipo;
          }
        }

        let _configs = {
          mando: this.arrColumns[id].mando,
          filtro: this.arrColumns[id].filtro,
          tipoScout: tipoScout,
          scout: this.arrColumns[id].scout.sigla,
        };

        this.arrColumns[id].title = "Ranking " + this.arrColumns[id].scout.nome;

        this.$http
          .get(
            url +
              `/clubes/rnkClubes/scoutClubes?tipoScout=${tipoScout}&scout=${_configs.scout}&mando=${_configs.mando}&filtro=${_configs.filtro}`
          )
          .catch((err) => console.error("ERROR_HTTP"))
          .then((res) => {
            this.arrColumns[id].tipo = res.data.data.tipo;
            this.arrColumns[id].kpis = res.data.data.kpis;
            this.arrColumns[id].data = res.data.data.ranking;
            this.loadingSaveConfig = false;
            this.arrColumns[id].config = false;
            this.setModules();
            this.loadCardTable[id] = true;
          });
      } else {
        this.error = true;
      }
    },
    async setModules(add = false) {
      let _configs = [];

      let aux = [];
      for (let config of this.arrColumns) {
        aux.push({
          config: config.config,
          filtro: config.filtro,
          mando: config.mando,
          title: config.title,
          scout: config.scout,
        });
      }
      _configs.push(aux);

      let confUsuario = { usuario: this.userId, config: _configs };

      this.$http
        .post(url + "/usuarios/" + this.userId + "/configs/setConfig", {
          nm_conf: "ordem_ranking_clubes",
          configs: { ...confUsuario },
        })
        .catch((err) => console.error("ERROR_HTTP"))
        .then((res) => {
          this.showContent = false;
          // this.$toasted.show('Alterações Salvas!', {
          //   position: 'top-center',
          //   duration: 3000,
          //   type: 'success',
          //   className: 'toasted-class'
          // })
          this.showContent = true;
          //if (add) window.location.reload()
        });
    },
    colorMando(mando) {
      return main.colorMando(mando);
    },
  },
};
</script>

<style lang="scss">
#rankingClubs {
  .table {
    td {
      background-color: #3f435e !important;
      border-color: #4c516d !important;
    }

    th {
      background-color: #232535 !important;
    }
  }

  .bg-header {
    background-color: #4d4d4d;
    border-radius: 10px;
  }
  .bd-header {
    border-bottom: 2px solid #333333;
  }
  .align-button-title {
    width: fit-content;
    position: relative;
    top: 50%;
    left: 0%;
    z-index: 2;
    transform: translate(-20%, -120%);
  }
  .align-button-block {
    width: fit-content;
    position: relative;
    top: 50%;
    left: 100%;
    z-index: 2;
    transform: translate(-80%, -30%);
  }
  .config {
    margin-top: 40px;
  }
  .acord-table {
    border: 2px solid transparent;
    margin: -1.2rem;
    width: calc(100% + 2.5rem);
    background-color: #333;
  }
  .t-container-2 {
    font-size: 0.8rem;
  }
  .t-container-2 th {
    text-align: center;
    vertical-align: center;
  }
  .t-container-2 td {
    border-color: #3a3a3a;
    text-align: center;
  }
  .block {
    min-width: 23vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1390px) {
    .acord-table {
      font-size: 0.7rem !important;
    }
  }
  @media (min-width: 342px) and (max-width: 1024px) {
    .col-content {
      font-size: 0.75rem;
    }
    .block {
      width: min-content !important;
      padding: 15px;
    }
    .h4-title {
      font-size: 0.8rem !important;
    }
    .acord-table {
      font-size: 0.6rem !important;
    }
    .td-acord {
      font-size: 0.6rem !important;
    }
    .mobile-config-bt {
      margin-top: 5px !important;
    }
    .warning {
      color: #f0c349;
    }
    .danger {
      color: #f04949;
    }
    .success {
      color: #78f049;
    }

    .group-stats {
      height: 100%;
    }
    .inputGroup {
      margin-top: 5px !important;
      float: left;
      width: calc(100% - 15px);
      font-size: 0.9em;
      margin: 5px 15px 5px 15px !important;
    }
    .form-select-2 {
      margin-top: 5px !important;
      transition: 0.5s;
      width: 100%;
      height: 32px;
      font-size: 0.9em;
      border-radius: 10px;
      padding: 3px;
      border: 2px solid #ccc;
    }
    .form-select-2:focus {
      border: 2px solid #202833;
    }
    .form-select-2 option {
      padding: 10px;
      cursor: pointer;
    }
    .form-select-2 option:hover {
      transition: 0.2s ease-in-out;
    }
    .form-input-2 {
      width: calc(100% - 10px);
      margin-top: 5px;
      height: 32px;
      font-size: 0.9em;
      border-radius: 7px;
      padding: 0 5px;
      color: rgb(100, 100, 100);
      border: none;
      border: 2px solid #ccc;
      background-color: white;
    }
    .form-input-2:focus {
      transition: 0.2s ease-in-out;
      border: none;
      border: 2px solid #f9621f;
    }
    .form-input-2::-webkit-input-placeholder,
    .form-input-2:-moz-placeholder {
      color: rgb(207, 70, 70);
    }

    .bt-round-icon {
      border-radius: 50%;
      border: 2px solid #ccc !important;
      color: #ccc !important;
      background-color: transparent !important;
    }
    .bt-round-icon:hover {
      transition: 0.2s ease-in-out;
      border-color: #f9621f !important;
      color: #f9621f !important;
    }

    .t-container {
      width: calc(100% - 1px);
      border-collapse: collapse !important;
    }
    .t-container td {
      padding: 5px 0;
      cursor: pointer;
      font-size: 13px;
      border: 1px solid #4d4d4d;
    }
    .t-container th {
      padding: 10px 0;
      color: white;
      font-size: 13px;
    }
    .t-container > tbody > tr:hover {
      transition: 0.2s ease;
      background-color: #00fffd;
      color: #4d4d4d;
    }

    .col-title,
    .col-sort {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
    .clube-escudo {
      width: auto;
      height: 22px;
    }
    .clube-sigla {
      width: 100%;
      font-size: 0.7em;
      font-weight: bold;
    }
    .acord-data {
      padding: 2px !important;
      width: 100%;
      background-color: #00fffd;
    }
  }
}
</style>
