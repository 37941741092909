<template>
  <vodal :show="show" @hide="$emit('close')" animation="zoom" class="p-vodal">
    <section class="vodal-section">
      <header class="vodal-header" v-if="!hideHeader">
        <h4 class="vodal-title">
          <slot name="title"></slot>
        </h4>
      </header>
      <div class="vodal-content">
        <slot></slot>
      </div>
      <footer v-if="showButtons" class="vodal-footer">
        <slot name="foo"></slot>
        <div class="vodal-bts">
          <button v-if="config.confirm" @click="$emit('confirm', config.action)" type="button" class="btn bt-confirm">{{ config.confirm }}</button>
          <button v-if="config.cancel" @click="$emit('close')" type="button" class="btn bt-cancel">{{ config.cancel }}</button>
          <button v-if="config.ok" @click="$emit('close')" type="button" class="btn bt-ok">{{ config.ok }}</button>
        </div>
      </footer>
    </section>
  </vodal>
</template>

<script>
import vodal from 'vodal'

export default {
  name: 'PainelVodal',
  components: {
    vodal
  },
  props: {
    "show": {
      type: Boolean,
      default: false
    }, 
    "hideHeader": {
      type: Boolean,
      default: false
    },
    "showButtons": {
      type: Boolean,
      default: false
    },
    "config": {
      type: Object,
      default: () => { 
        return {
          confirm: '',
          cancel: '',
          ok: ''
        }
      }
    }
  }
}
</script>

<style>
@media (min-width: 342px) and (max-width: 1024px) {
  .vodal {
    width: auto !important;
  }
  .p-vodal .vodal-dialog {
    padding: 5px !important;
    width: calc(100% - 20px) !important;
  }
  .p-vodal {
    padding: 0 !important;
  }
  .vodal-title {
    font-size: 18px !important;
  }
}
@media (min-width: 1025px) {
  .p-vodal .vodal-dialog {
    width: auto !important;
  }
}
.p-vodal {
  overflow-y: auto;
  padding: 20px 10px;
  display: flex;
  z-index: 99999999;
  background-color: rgba(0,0,0,0.5);
}
.p-vodal .vodal {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.150);
}
.p-vodal .vodal-mask {
  position: absolute;
  width: 100%;
  height: 100vh;
}
.p-vodal .vodal-dialog {
  position: relative;
  margin: auto;
  display: table;
  padding: 35px;
}
.vodal-section {
  width: 100%;
  height: 100%;
  padding: 0px;
  color: white;
  background-color: black;
  border-radius: 20px;
}
.vodal-header {
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  color: white;
  width: calc(100% - 10px);
  min-height: 30px;
  float: left;
  padding: 5px;
}
.vodal-footer {
  border-top: 1 px solid #eeeeee;
  width: 100%;
  padding: 20px 0 10px 0;
}
.vodal-content {
  width: 100%;
  padding: 0 0 10px 0;
}
.vodal-title {
  font-size: 22px;
  margin: 0;
}
.vodal-bts {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.vodal-bts button {
  flex-basis: 100px;
  flex-grow: 1;
  margin: 0 5px;
  padding: 15px 0;
  font-size: 16px;
}
</style>
