<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="rankingAthletes" title="Ranking de Atletas">
      <template slot="options">
        <div>
          <restrict-access slug="rankingAtletas.newColumn">
            <b-button
              :expanded="isMobile"
              type="is-independence"
              @click="openNewModule"
              >Criar novo Ranking</b-button
            >
          </restrict-access>
        </div>
      </template>

      <section class="group-stats" style="max-width: 90vw">
        <div class="columns is-multiline">
          <div class="content">
            <!-- Conteudo do grupo -->
            <div
              class="column is-full is-centered"
              :style="{
                'padding-left': $mq == 'sm' ? 0 : '0.75rem',
                'padding-right': $mq == 'sm' ? 0 : '0.75rem',
              }"
            >
              <div v-if="showContent" class="stats-content">
                <container
                  @drop="onDrop($event)"
                  :get-child-payload="getChildPayload"
                  :should-accept-drop="shouldAcceptDrop"
                  group-name="stats"
                  orientation="horizontal"
                  :non-drag-area-selector="'.col-content'"
                >
                  <draggable
                    v-for="(card, idCard) of cards"
                    :key="'drag-' + idCard"
                    :id="'drag-' + idCard"
                  >
                    <div
                      v-if="loadCards[idCard]"
                      class="block"
                      :style="{ width: 'min-content' }"
                    >
                      <!-- Buttons (Close and Configure) -->
                      <div class="align-button-block">
                        <b-button
                          @click="deleteColumn(idCard)"
                          type="is-danger"
                          icon-pack="fas"
                          size="is-small"
                          icon-left="times"
                          rounded
                        ></b-button>
                      </div>
                      <div class="align-button-title">
                        <restrict-access slug="rankingAtletas.configColumn">
                          <b-button
                            @click="toggleConfig(idCard)"
                            type="is-primary"
                            size="is-small"
                            icon-pack="fas"
                            icon-left="sliders-h"
                            rounded
                          ></b-button>
                        </restrict-access>
                      </div>

                      <!--  Title and Subtitle -->
                      <div class="info-title">
                        <h4 class="h4-title">{{ card.title }}</h4>
                        <h4
                          class="h4-subtitle"
                          :style="{
                            'background-color': colorMando(card.mando),
                          }"
                        >
                          {{ card.scout.sigla }} - {{ card.filtro }} -
                          {{
                            (card.mando === "casa"
                              ? "Mandante"
                              : card.mando === "fora"
                              ? "Visitante"
                              : "TOTAL") | capitalize
                          }}
                        </h4>
                      </div>

                      <!-- Config para editar a coluna -->
                      <div
                        v-show="card.config"
                        :key="'coluna_' + idCard"
                        :id="'coluna_' + idCard"
                        class="info-row"
                        style="z-index: 9999; position: relative"
                      >
                        <div class="config">
                          <b-field label="Posição">
                            <b-select v-model="card.posicao" expanded>
                              <option
                                v-for="(opt, index) in arr_posicoes"
                                :key="index"
                                :value="opt"
                                >{{ opt.nome }}</option
                              >
                            </b-select>
                          </b-field>

                          <b-field
                            label="Scout"
                            :type="
                              error && typeof card.scout !== 'object'
                                ? 'is-danger'
                                : 'is-light'
                            "
                            :message="
                              error && typeof card.scout !== 'object'
                                ? 'Escolha um Scout'
                                : ''
                            "
                          >
                            <b-select v-model="card.scout" expanded>
                              <option
                                v-for="(opt, index) in arr_scouts_kpi"
                                :key="index"
                                :value="opt"
                                >{{ opt.nome }}</option
                              >
                            </b-select>
                          </b-field>

                          <b-field
                            label="Mando de Jogo"
                            :type="
                              error && card.mando == ''
                                ? 'is-danger'
                                : 'is-light'
                            "
                            :message="
                              error && card.mando == ''
                                ? 'Escolha o Mando de Jogo'
                                : ''
                            "
                          >
                            <div>
                              <b-switch
                                v-model="card.mando"
                                true-value="casa"
                                :name="'mando-' + idCard"
                                >Mandante</b-switch
                              >
                              <b-switch
                                v-model="card.mando"
                                true-value="fora"
                                :name="'mando-' + idCard"
                                >Visitante</b-switch
                              >
                              <b-switch
                                v-model="card.mando"
                                true-value="ambos"
                                :name="'mando-' + idCard"
                                >Ambos</b-switch
                              >
                            </div>
                          </b-field>

                          <b-field
                            label="Filtro"
                            :type="
                              error && card.filtro == ''
                                ? 'is-danger'
                                : 'is-light'
                            "
                            :message="
                              error && card.filtro == ''
                                ? 'Escolha o Filtro'
                                : ''
                            "
                          >
                            <b-select v-model="card.filtro" expanded>
                              <option
                                v-for="opt in arr_filters"
                                :key="opt"
                                :value="opt"
                                >{{ opt }}</option
                              >
                            </b-select>
                          </b-field>

                          <b-field
                            style="margin-top: 25px;"
                            :message="
                              loadingSaveConfig
                                ? 'Salvando e Atualizando Dados...'
                                : ''
                            "
                          >
                            <b-button
                              @click="saveColumn(idCard)"
                              type="is-primary"
                              :loading="loadingSaveConfig"
                              >Atualizar</b-button
                            >
                          </b-field>
                        </div>
                      </div>

                      <!-- Conteudo de cada coluna -->
                      <div class="col-content" style="border-radius: 7px;">
                        <b-table
                          :key="
                            'table-' +
                              idCard +
                              (card.posicao ? card.posicao.nome : '') +
                              card.scout.sigla +
                              card.mando +
                              card.filtro
                          "
                          :id="
                            'table-' +
                              idCard +
                              (card.posicao ? card.posicao.nome : '') +
                              card.scout.sigla +
                              card.mando +
                              card.filtro
                          "
                          :data="card.data"
                          :mobile-cards="false"
                          ref="table"
                          narrowed
                          :sticky-header="true"
                          height="55vh"
                          v-if="loadCardTable[idCard]"
                        >
                          <template slot-scope="props" slot="header">
                            <div
                              v-if="props.column.sortable"
                              class="label-table"
                              @click="statusSort(idCard, props.index)"
                              v-tooltip.bottom="{
                                content: props.column.meta,
                                classes: 'home-tooltip',
                              }"
                            >
                              <span
                                v-if="
                                  props.column.sortable &&
                                    showVisibleSort[idCard][props.index]
                                "
                              >
                                <b-icon
                                  icon="sort"
                                  pack="fas"
                                  size="is-small"
                                ></b-icon>
                              </span>
                              <i
                                v-if="props.column.meta"
                                class="fas fa-question-circle"
                                style="margin-right: 2px;"
                              ></i>
                              <p>{{ props.column.label }}</p>
                            </div>
                            <div v-else class="label-table">
                              <i
                                v-if="props.column.meta"
                                class="fas fa-question-circle"
                                style="margin-right: 2px;"
                              ></i>
                              <p>{{ props.column.label }}</p>
                            </div>
                          </template>

                          <template slot-scope="props">
                            <b-table-column
                              field="mando"
                              label="Mando Atual"
                              centered
                            >
                              <b-tag
                                :type="
                                  props.row.mando == 'casa'
                                    ? 'is-casa'
                                    : 'is-fora'
                                "
                                rounded
                                >{{
                                  props.row.mando.substr(0, 1).toUpperCase()
                                }}</b-tag
                              >
                            </b-table-column>

                            <b-table-column
                              field="atleta"
                              label="Atletas"
                              sortable
                              centered
                            >
                              {{ props.row.atleta }}
                            </b-table-column>

                            <b-table-column
                              v-if="
                                !card.posicao ||
                                  card.posicao.sigla !==
                                    props.row.posicao_codigo
                              "
                              field="posicao_codigo"
                              label="Posição"
                              centered
                              sortable
                            >
                              {{ props.row.posicao_codigo.toUpperCase() }}
                            </b-table-column>

                            <b-table-column
                              field="time_sigla"
                              label="Clubes"
                              centered
                              sortable
                            >
                              {{ props.row.time_sigla }}
                            </b-table-column>

                            <b-table-column
                              v-if="card.tipo === 'scout'"
                              field="scout"
                              :label="card.scout.sigla"
                              centered
                              sortable
                            >
                              {{ props.row.scout }}
                            </b-table-column>

                            <b-table-column
                              v-if="card.tipo === 'scout'"
                              field="jogos"
                              label="Jogos"
                              centered
                              sortable
                            >
                              {{ props.row.jogos }}
                            </b-table-column>

                            <b-table-column
                              v-if="card.tipo === 'scout'"
                              field="media"
                              label="Média"
                              centered
                              sortable
                            >
                              {{ props.row.media.toFixed(2) }}
                            </b-table-column>

                            <!-- KPI -->
                            <b-table-column
                              v-for="(kpi, a) of card.kpis"
                              :key="a"
                              :field="kpi.sigla"
                              :label="kpi.sigla"
                              :meta="kpi.nome"
                              centered
                              sortable
                            >
                              {{
                                kpi.formato.indexOf("%") !== -1
                                  ? (props.row[kpi.sigla] * 100).toFixed(
                                      kpi.formato.replace("%", "")
                                    ) + "%"
                                  : props.row[kpi.sigla].toFixed(kpi.formato)
                              }}
                            </b-table-column>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </draggable>
                </container>
              </div>
            </div>
          </div>
        </div>

        <!-- Nova Coluna -->
        <b-modal :active.sync="showVodal" has-modal-card>
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Nova Coluna</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-field label="Posição" :type="error ? 'is-danger' : 'is-light'" :message="error ? 'Escolha uma Posição' : ''"> -->
              <b-field label="Posição">
                <b-select v-model="column.posicao" expanded>
                  <option
                    v-for="(opt, index) in arr_posicoes"
                    :key="index"
                    :value="opt"
                    >{{ opt.nome }}</option
                  >
                </b-select>
              </b-field>

              <b-field
                label="Scout"
                :type="
                  error && typeof column.scout !== 'object'
                    ? 'is-danger'
                    : 'is-light'
                "
                :message="
                  error && typeof column.scout !== 'object'
                    ? 'Escolha um Scout'
                    : ''
                "
              >
                <b-select v-model="column.scout" expanded>
                  <option
                    v-for="(opt, index) in arr_scouts_kpi"
                    :key="index"
                    :value="opt"
                    >{{ opt.nome }}</option
                  >
                </b-select>
              </b-field>

              <b-field
                label="Mando de Jogo"
                :type="error && column.mando == '' ? 'is-danger' : 'is-light'"
                :message="
                  error && column.mando == '' ? 'Escolha o Mando de Jogo' : ''
                "
              >
                <div>
                  <b-switch
                    v-model="column.mando"
                    true-value="casa"
                    name="mando-vodal"
                    >Mandante</b-switch
                  >
                  <b-switch
                    v-model="column.mando"
                    true-value="fora"
                    name="mando-vodal"
                    >Visitante</b-switch
                  >
                  <b-switch
                    v-model="column.mando"
                    true-value="ambos"
                    name="mando-vodal"
                    >Ambos</b-switch
                  >
                </div>
              </b-field>

              <b-field
                label="Filtro"
                :type="error && column.filtro == '' ? 'is-danger' : 'is-light'"
                :message="
                  error && column.filtro == '' ? 'Escolha o Filtro' : ''
                "
              >
                <b-select v-model="column.filtro" expanded>
                  <option v-for="opt in arr_filters" :key="opt" :value="opt">{{
                    opt
                  }}</option>
                </b-select>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button @click="showVodal = false" type="is-danger" outlined
                >Cancelar</b-button
              >
              <button @click="addColumn()" class="button is-success">
                Adicionar
              </button>
            </footer>
          </div>
        </b-modal>
      </section>
    </BasePage>
  </BaseLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Container, Draggable } from "vue-smooth-dnd";

import PainelVodal from "@/components/helpers/vodal/PainelVodal";
import restrictAccess from "@/components/restrictAccess/restrictAccess";

import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";

import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

export default {
  name: "RankingAthletes",
  mixins: [championshipMixins],
  components: {
    container: Container,
    draggable: Draggable,
    "p-vodal": PainelVodal,
    "restrict-access": restrictAccess,
    BaseLayout,
    BasePage,
  },
  data() {
    return {
      arr_scouts_kpi: __scouts_kpi,
      arr_scouts: __scouts,
      arr_posicoes: [],
      arrColumns: [],
      column: {
        mando: "",
        filtro: "3 jogos",
      },
      showContent: true,
      showVodal: false,
      error: false,
      arr_filters: __filters,
      loadingSaveConfig: false,
      loadCards: [],
      loadCardTable: [],
      showVisibleSort: [],
      max_columns: false,
      defaultcol: [],
      deleteId: null,
    };
  },
  async created() {
    let loader = this.$loading.show();

    await this.loadChampionships();
    this.setDefaultToBrazilChampionship();

    let userId = this.userId;
    let nm_conf = "ordem_ranking_atletas";

    await this.getPosicoes();
    this.arr_posicoes = [
      { sigla: null, nome: "Todas as Posições" },
      ...this.posicoes,
    ];

    await Promise.all([
      this.$http.get(url + `/clubes/rnkAtletas/scoutKPI`),
      this.$http.get(url + "/usuarios/" + userId + "/configs/" + nm_conf),
    ])
      .catch((err) => console.log("ERROR_HTTP"))
      .then((res) => {
        this.arr_scouts_kpi = res[0].data.data;

        let _data = JSON.parse(res[1].data.data[0].valor);

        if (_data.length === 1) {
          _data = _data[0];
        }

        this.defaultcol = JSON.parse(res[1].data.data[0].valor_padrao);
        this.defaultcol = this.defaultcol[0];

        for (let card of _data) {
          let tipoScout = "";
          for (const scout_kpi of this.arr_scouts_kpi) {
            if (scout_kpi.sigla === card.scout.sigla) {
              tipoScout = scout_kpi.tipo;
            }
          }

          let posicaoSigla = card.posicao
            ? card.posicao.sigla
              ? card.posicao.sigla
              : ""
            : "";
          let posicaoNome = card.posicao
            ? card.posicao.nome
            : "Todas as Posições";
          let filtro =
            card.filto != "todos" ? card.filtro.replace(" ", "%") : card.filtro;

          this.$http
            .get(
              url +
                `/clubes/rnkAtletas/scoutAtletas?tipoScout=${tipoScout}&scout=${card.scout.sigla}&mando=${card.mando}&filtro=${filtro}&posicao=${posicaoSigla}`
            )
            .catch((err) => console.error("ERROR_HTTP"))
            .then((res) => {
              card.tipo = res.data.data.tipo;
              card.kpis = res.data.data.kpis;
              card.data = res.data.data.ranking;

              this.loadCards.push(true);
              this.loadCardTable.push(true);

              let visible = [true, true, true, true, true, true, true];
              if (card.kpis) {
                for (const kpi of card.kpis) {
                  visible.push(true);
                }
              }
              this.showVisibleSort.push(visible);
            });
          this.arrColumns.push(card);
          loader.hide();
        }
      });
  },
  computed: {
    ...mapGetters(["userId", "posicoes"]),
    cards() {
      return this.arrColumns;
    },
  },
  methods: {
    ...mapActions(["getPosicoes"]),
    statusSort(card, index) {
      this.showVisibleSort[card] = this.showVisibleSort[card].map(
        (el, index) => true
      );
      this.showVisibleSort[card][index] = false;
    },
    openNewModule(idPosicao) {
      this.error = false;
      this.showVodal = true;
    },
    hideVodal() {
      this.column = { mando: "", filtro: "3 jogos" };
      this.showVodal = false;
      this.arrColumns.map((el) => {
        el.config = false;
        return el;
      });
    },
    verifyColumn() {
      if (this.arrColumns.length < 5) {
        // if (typeof this.column.posicao != 'object') return false
        if (this.column.mando == "") return false;
        if (this.column.filtro == "") return false;
        if (typeof this.column.scout != "object") return false;
        else return true;
      } else {
        this.max_columns = true;
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Limite de colunas atingido! (máx. 5)",
          type: "is-warning",
          hasIcon: true,
        });

        this.hideVodal();
        return false;
      }
    },
    async addColumn() {
      this.error = false;

      if (this.verifyColumn()) {
        if (this.error) this.error = false;
        this.column.config = false;

        let loader = this.$loading.show();

        let tipoScout = "";
        for (const scout_kpi of this.arr_scouts_kpi) {
          if (scout_kpi.sigla === this.column.scout.sigla) {
            tipoScout = scout_kpi.tipo;
          }
        }

        let posicaoSigla = this.column.posicao
          ? this.column.posicao.sigla
            ? this.column.posicao.sigla
            : ""
          : "";
        let posicaoNome = this.column.posicao
          ? this.column.posicao.nome
          : "Todas as Posições";

        let _configs = {
          mando: this.column.mando,
          tipoScout: tipoScout,
          scout: this.column.scout.sigla,
          filtro: this.column.filtro,
          posicao: posicaoSigla,
        };

        this.column.title = "Análise " + posicaoNome;

        let filtro = _configs.filtro.replace(" ", "%");
        this.$http
          .get(
            url +
              `/clubes/rnkAtletas/scoutAtletas?tipoScout=${tipoScout}&scout=${_configs.scout}&mando=${_configs.mando}&filtro=${filtro}&posicao=${_configs.posicao}`
          )
          .catch((err) => console.error("ERROR_HTTP"))
          .then((res) => {
            this.showContent = false;

            this.column.tipo = res.data.data.tipo;
            this.column.kpis = res.data.data.kpis;
            this.column.data = res.data.data.ranking;

            this.column.sortConq = "";
            this.column.sortCed = "";

            let el = this.column;
            let group = el.group;
            delete el.group;

            this.loadCards.push(true);
            this.loadCardTable.push(true);
            this.arrColumns.push(el);

            this.showVisibleSort = this.showVisibleSort
              .map((el) => el)
              .map((elm, index) => [true, true, true, true, true, true, true]);

            let visible = [true, true, true, true, true, true, true];
            if (el.kpis) {
              for (const kpi of el.kpis) {
                visible.push(true);
              }
            }
            this.showVisibleSort.push(visible);

            this.hideVodal();
            this.setModules(true);
            this.showContent = true;
            loader.hide();
          });
      } else {
        this.error = this.max_columns ? false : true;
        this.max_columns = false;
      }
    },
    toggleConfig(id) {
      this.arrColumns[id].config = !this.arrColumns[id].config;
      let oColumnCard = document.getElementById("coluna_" + id);
      if (this.arrColumns[id].config) oColumnCard.style.display = "";
      if (!this.arrColumns[id].config) oColumnCard.style.display = "none";
    },
    async deleteColumn(id) {
      this.deleteId = id;
      // this.loadCards = this.loadCards.map(el => false)
      // this.loadCardTable = this.loadCardTable.map(el => false)

      this.loadCardTable = this.loadCardTable.map((el, i) => i < id);

      // let title = "<p>"+this.arrColumns[id].title +"<br>"+
      //             (this.arrColumns[id].scout.sigla +" - "+
      //             this.arrColumns[id].filtro +" - "+
      //             ((this.arrColumns[id].mando === "casa") ?
      //               "Mandante" :
      //               ((this.arrColumns[id].mando === "fora") ?
      //                 "Visitante" :
      //                 "TOTAL"))).toUpperCase()+"</p>"

      // this.$buefy.dialog.confirm({
      //   title: "ATENÇÃO!",
      //   message: `Deseja realmente excluir a coluna ${title}?`,
      //   type: "is-warning",
      //   hasIcon: true,
      //   onCancel: async () => {
      //     this.loadCardTable = this.loadCardTable.map(el => true)
      //   },
      //   onConfirm: async () => {

      this.showContent = false;
      this.showVisibleSort = [];

      this.arrColumns.splice(this.deleteId, 1);
      this.loadCardTable.splice(this.deleteId, 1);
      this.loadCards.splice(this.deleteId, 1);

      this.deleteId = null;

      this.showVisibleSort = this.arrColumns.map((el) => {
        let visible = [true, true, true, true, true, true, true];
        if (el.kpis) {
          for (const kpi of el.kpis) {
            visible.push(true);
          }
        }
        return visible;
      });

      this.loadCardTable = this.loadCardTable.map((el) => true);
      this.loadCards = this.loadCards.map((el) => true);
      this.showContent = true;

      await this.setModules();
      await this.$forceUpdate();
      //   }
      // });
    },
    ordColumn(id, ord) {
      if (ord == "ced") {
        let sort = this.arrColumns[id].sortCed;

        if (sort === "") sort = "down";
        else if (sort === "down") sort = "up";
        else if (sort === "up") sort = "down";

        this.arrColumns[id].sortCed = sort;
        this.arrColumns[id].data = this.arrColumns[id].data.sort(function(
          a,
          b
        ) {
          b.type.ced - a.type.ced;
          if (sort === "down") {
            if (a.type.ced > b.type.ced) return 1;
            else if (a.type.ced < b.type.ced) return -1;
            else return 0;
          } else if (sort === "up") {
            if (a.type.ced < b.type.ced) return 1;
            else if (a.type.ced > b.type.ced) return -1;
            else return 0;
          }
        });
      }
      if (ord == "con") {
        let sort = this.columns[id].sortConq;

        if (sort === "") sort = "down";
        else if (sort === "down") sort = "up";
        else if (sort === "up") sort = "down";

        this.arrColumns[id].sortConq = sort;
        this.arrColumns[id].data = this.arrColumns[id].data.sort(function(
          a,
          b
        ) {
          b.type.con - a.type.con;
          if (sort === "down") {
            if (a.type.con > b.type.con) return 1;
            else if (a.type.con < b.type.con) return -1;
            else return 0;
          } else if (sort === "up") {
            if (a.type.con < b.type.con) return 1;
            else if (a.type.con > b.type.con) return -1;
            else return 0;
          }
        });
      }
    },
    resetColumns() {
      this.arrColumns = [];

      for (let el of this.defaultcol) {
        let tipoScout = "";
        for (const scout_kpi of this.arr_scouts_kpi) {
          if (scout_kpi.sigla === el.scout.sigla) {
            tipoScout = scout_kpi.tipo;
          }
        }

        let posicaoSigla = el.posicao
          ? el.posicao.sigla
            ? el.posicao.sigla
            : ""
          : "";
        let posicaoNome = el.posicao ? el.posicao.nome : "Todas as Posições";

        let _configs = {
          mando: el.mando,
          tipoScout: tipoScout,
          scout: el.scout.sigla,
          filtro: el.filtro,
          posicao: posicaoSigla,
        };

        el.title = "Analise " + posicaoNome;

        this.$http
          .get(
            url +
              `/clubes/rnkAtletas/scoutAtletas?tipoScout=${tipoScout}&scout=${_configs.scout}&mando=${_configs.mando}&filtro=${_configs.filtro}&posicao=${_configs.posicao}`
          )
          .catch((err) => console.error("HTTP_ERROR"))
          .then((res) => {
            el.tipo = res.data.data.tipo;
            el.kpis = res.data.data.kpis;
            el.data = res.data.data.ranking;
            el.sortConq = "";
            el.sortCed = "";

            this.arrColumns.push(el);
          });
      }

      this.setModules();
    },
    shouldAcceptDrop(sourceContainerOptions, payload) {
      return true;
    },
    getChildPayload(index) {
      return this.arrColumns[index];
    },
    onDrop(dropResult) {
      this.arrColumns = this.applyDrag(this.arrColumns, dropResult);
      this.setModules();
    },
    applyDrag(arr, dropResult) {
      const { removedIndex, addedIndex, payload } = dropResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    verifyCard(id) {
      if (typeof this.arrColumns[id].posicao != "object") return false;
      if (this.arrColumns[id].mando == "") return false;
      if (this.arrColumns[id].filtro == "") return false;
      if (typeof this.arrColumns[id].scout != "object") return false;
      else return true;
    },
    saveColumn(id) {
      this.loadCardTable[id] = false;
      this.error = false;

      if (this.verifyCard(id)) {
        if (this.error) this.error = false;
        this.loadingSaveConfig = true;

        let tipoScout = "";
        for (const scout_kpi of this.arr_scouts_kpi) {
          if (scout_kpi.sigla === this.arrColumns[id].scout.sigla) {
            tipoScout = scout_kpi.tipo;
          }
        }

        let posicaoSigla = this.arrColumns[id].posicao
          ? this.arrColumns[id].posicao.sigla
            ? this.arrColumns[id].posicao.sigla
            : ""
          : "";
        let posicaoNome = this.arrColumns[id].posicao
          ? this.arrColumns[id].posicao.nome
          : "Todas as Posições";

        let _configs = {
          mando: this.arrColumns[id].mando,
          posicao: posicaoSigla,
          tipoScout: tipoScout,
          scout: this.arrColumns[id].scout.sigla,
          filtro: this.arrColumns[id].filtro,
        };
        this.arrColumns[id].title = "Analise " + posicaoNome;

        this.$http
          .get(
            url +
              `/clubes/rnkAtletas/scoutAtletas?tipoScout=${tipoScout}&scout=${_configs.scout}&mando=${_configs.mando}&filtro=${_configs.filtro}&posicao=${_configs.posicao}`
          )
          .catch((err) => console.error("ERROR_HTTP"))
          .then((res) => {
            this.arrColumns[id].tipo = res.data.data.tipo;
            this.arrColumns[id].kpis = res.data.data.kpis;
            this.arrColumns[id].data = res.data.data.ranking;
            this.loadingSaveConfig = false;
            this.arrColumns[id].config = false;
            this.setModules();
            this.loadCardTable[id] = true;
          });
      } else {
        this.error = true;
      }
    },
    async setModules(add = false) {
      let _configs = [];

      let aux = [];
      for (let config of this.arrColumns) {
        aux.push({
          config: config.config,
          filtro: config.filtro,
          mando: config.mando,
          title: config.title,
          scout: config.scout,
          posicao: config.posicao,
        });
      }
      _configs.push(aux);

      let confUsuario = { usuario: this.userId, config: _configs };

      this.$http
        .post(url + "/usuarios/" + this.userId + "/configs/setConfig", {
          nm_conf: "ordem_ranking_atletas",
          configs: { ...confUsuario },
        })
        .catch((err) => console.error("ERROR_HTTP"))
        .then((res) => {
          this.showContent = false;
          // this.$toasted.show('Alterações Salvas!', {
          //   position: 'top-center',
          //   duration: 3000,
          //   type: 'success',
          //   className: 'toasted-class'
          // })
          this.showContent = true;
          //if (add) window.location.reload()
        });
    },
    colorMando(mando) {
      return main.colorMando(mando);
    },
  },
};
</script>

<style lang="scss">
#rankingAthletes {
  @media (min-width: 342px) and (max-width: 1024px) {
    .col-content {
      font-size: 0.75rem;
      height: auto !important;
    }
    .block {
      min-width: 90vw !important;
      width: min-content !important;
    }
  }
  .config {
    margin-top: 40px;
  }
  .block {
    min-width: 32vw;
    width: min-content;
  }

  .table {
    background-color: #3f435e !important;

    td {
      background-color: #3f435e !important;
    }

    th {
      background-color: #232535 !important;
    }
  }
}
</style>
