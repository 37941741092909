var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{attrs:{"championships":_vm.championships,"is-page-for-default-championship":""}},[_c('BasePage',{attrs:{"id":"rankingClubs","title":"Ranking de Clubes","subtitle":_vm.subtitle}},[_c('template',{slot:"options"},[_c('div',[_c('restrict-access',{attrs:{"slug":"rankingClubes.newColumn"}},[_c('b-button',{attrs:{"expanded":_vm.isMobile,"type":"is-independence"},on:{"click":_vm.openNewModule}},[_vm._v("Criar novo Ranking")])],1)],1)]),_c('section',{staticClass:"group-stats"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"column is-full is-centered",style:({
              'padding-left': _vm.$mq == 'sm' ? 0 : '0.75rem',
              'padding-right': _vm.$mq == 'sm' ? 0 : '0.75rem',
            })},[(_vm.showContent)?_c('div',{staticClass:"stats-content"},[_c('container',{attrs:{"get-child-payload":_vm.getChildPayload,"should-accept-drop":_vm.shouldAcceptDrop,"group-name":"stats","orientation":"horizontal","non-drag-area-selector":'.col-content'},on:{"drop":function($event){return _vm.onDrop($event)}}},_vm._l((_vm.arrColumns),function(card,idCard){return _c('draggable',{key:'drag-' + idCard,attrs:{"id":'drag-' + idCard}},[(_vm.loadCards[idCard])?_c('div',{staticClass:"block",style:({ width: 'min-content' })},[_c('div',{staticClass:"align-button-block"},[_c('b-button',{attrs:{"type":"is-danger","icon-pack":"fas","size":"is-small","icon-left":"times","rounded":""},on:{"click":function($event){return _vm.deleteColumn(idCard)}}})],1),_c('div',{staticClass:"align-button-title"},[_c('restrict-access',{attrs:{"slug":"rankingClubes.configColumn"}},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","icon-pack":"fas","icon-left":"sliders-h","rounded":""},on:{"click":function($event){return _vm.toggleConfig(idCard)}}})],1)],1),_c('div',{staticClass:"info-title"},[_c('h4',{staticClass:"h4-title"},[_vm._v(_vm._s(card.title))]),_c('h4',{staticClass:"h4-subtitle",style:({
                          'background-color': _vm.colorMando(card.mando),
                        })},[_vm._v(" "+_vm._s(card.scout.sigla)+" - "+_vm._s(card.filtro)+" - "+_vm._s(_vm._f("capitalize")((card.mando === "casa" ? "Mandante" : card.mando === "fora" ? "Visitante" : "TOTAL")))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(card.config),expression:"card.config"}],key:'coluna_' + idCard,staticClass:"info-row",staticStyle:{"z-index":"9999","position":"relative"},attrs:{"id":'coluna_' + idCard}},[_c('div',{staticClass:"config"},[_c('b-field',{attrs:{"label":"Scout","type":_vm.error && typeof card.scout !== 'object'
                              ? 'is-danger'
                              : 'is-light',"message":_vm.error && typeof card.scout !== 'object'
                              ? 'Escolha um Scout'
                              : ''}},[_c('b-select',{attrs:{"expanded":""},model:{value:(card.scout),callback:function ($$v) {_vm.$set(card, "scout", $$v)},expression:"card.scout"}},_vm._l((_vm.arr_scouts_kpi),function(opt,index){return _c('option',{key:index,domProps:{"value":opt}},[_vm._v(_vm._s(opt.nome))])}),0)],1),_c('b-field',{staticStyle:{"margin-top":"20px"},attrs:{"label":"Mando de Jogo","type":_vm.error && card.mando == ''
                              ? 'is-danger'
                              : 'is-light',"message":_vm.error && card.mando == ''
                              ? 'Escolha o Mando de Jogo'
                              : ''}},[_c('div',[_c('b-switch',{attrs:{"true-value":"casa","name":'mando-' + idCard},model:{value:(card.mando),callback:function ($$v) {_vm.$set(card, "mando", $$v)},expression:"card.mando"}},[_vm._v("Mandante")]),_c('b-switch',{attrs:{"true-value":"fora","name":'mando-' + idCard},model:{value:(card.mando),callback:function ($$v) {_vm.$set(card, "mando", $$v)},expression:"card.mando"}},[_vm._v("Visitante")]),_c('b-switch',{attrs:{"true-value":"ambos","name":'mando-' + idCard},model:{value:(card.mando),callback:function ($$v) {_vm.$set(card, "mando", $$v)},expression:"card.mando"}},[_vm._v("Ambos")])],1)]),_c('b-field',{staticStyle:{"margin-top":"20px"},attrs:{"label":"Filtro","type":_vm.error && card.filtro == ''
                              ? 'is-danger'
                              : 'is-light',"message":_vm.error && card.filtro == ''
                              ? 'Escolha o Filtro'
                              : ''}},[_c('b-select',{attrs:{"expanded":""},model:{value:(card.filtro),callback:function ($$v) {_vm.$set(card, "filtro", $$v)},expression:"card.filtro"}},_vm._l((_vm.arr_filters),function(opt){return _c('option',{key:opt,domProps:{"value":opt}},[_vm._v(_vm._s(opt))])}),0)],1),_c('b-field',{staticStyle:{"margin-top":"25px"},attrs:{"message":_vm.loadingSaveConfig
                              ? 'Salvando e Atualizando Dados...'
                              : ''}},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.loadingSaveConfig},on:{"click":function($event){return _vm.saveColumn(idCard)}}},[_vm._v("Atualizar")])],1)],1)]),_c('div',{staticClass:"col-content"},[(_vm.loadCardTable[idCard])?_c('b-table',{key:'table-' +
                            idCard +
                            card.scout.sigla +
                            card.mando +
                            card.filtro,ref:"table",refInFor:true,attrs:{"id":'table-' +
                            idCard +
                            card.scout.sigla +
                            card.mando +
                            card.filtro,"data":card.data,"mobile-cards":false,"detailed":card.tipo === 'scout',"detail-key":"ranking","show-detail-icon":true,"narrowed":"","sticky-header":true,"height":"55vh"},on:{"details-open":function (row, a) { return _vm.ativeAcord(idCard, row.ranking - 1); }},scopedSlots:_vm._u([{key:"header",fn:function(props){return [(props.column.sortable)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                              content: props.column.meta,
                              classes: 'home-tooltip',
                            }),expression:"{\n                              content: props.column.meta,\n                              classes: 'home-tooltip',\n                            }",modifiers:{"bottom":true}}],staticClass:"label-table centralize-row",on:{"click":function($event){return _vm.statusSort(idCard, props.index)}}},[(
                                props.column.sortable &&
                                  _vm.showVisibleSort[idCard][props.index]
                              )?_c('span',[_c('b-icon',{attrs:{"icon":"sort","pack":"fas","size":"is-small"}})],1):_vm._e(),(props.column.meta)?_c('i',{staticClass:"fas fa-question-circle",staticStyle:{"margin-right":"2px"}}):_vm._e(),_c('p',[_vm._v(_vm._s(props.column.label))])]):_c('div',{staticClass:"label-table centralize-row"},[(props.column.meta)?_c('i',{staticClass:"fas fa-question-circle",staticStyle:{"margin-right":"2px"}}):_vm._e(),_c('p',[_vm._v(_vm._s(props.column.label))])])]}},{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"mando","label":"Mando Atual","centered":""}},[_c('b-tag',{attrs:{"type":props.row.mando == 'casa'
                                  ? 'is-casa'
                                  : 'is-fora',"rounded":""}},[_vm._v(_vm._s(props.row.mando.substr(0, 1).toUpperCase()))])],1),_c('b-table-column',{attrs:{"field":"time_sigla","label":"Clubes","centered":"","sortable":""},on:{"click":function($event){return _vm.toggle(props.row)}}},[_vm._v(" "+_vm._s(props.row.time_sigla)+" ")]),(card.tipo == 'scout')?_c('b-table-column',{attrs:{"field":"scout","label":card.scout.sigla,"centered":"","sortable":""},on:{"click":function($event){return _vm.toggle(props.row)}}},[(card.tipo == 'scout')?_c('p',[_vm._v(" "+_vm._s(props.row.scout)+" ")]):_vm._e()]):_vm._e(),(card.tipo == 'scout')?_c('b-table-column',{attrs:{"field":"jogos","label":"Jogos","centered":"","sortable":""},on:{"click":function($event){return _vm.toggle(props.row)}}},[(card.tipo == 'scout')?_c('p',[_vm._v(" "+_vm._s(props.row.jogos)+" ")]):_vm._e()]):_vm._e(),(card.tipo == 'scout')?_c('b-table-column',{attrs:{"field":"media","label":"Média","centered":"","sortable":""},on:{"click":function($event){return _vm.toggle(props.row)}}},[(card.tipo == 'scout')?_c('p',[_vm._v(" "+_vm._s(props.row.media.toFixed(2))+" ")]):_vm._e()]):_vm._e(),_vm._l((card.kpis),function(kpi,a){return _c('b-table-column',{key:a,attrs:{"field":kpi.sigla,"label":kpi.sigla,"meta":kpi.nome,"centered":"","sortable":""}},[_vm._v(" "+_vm._s(kpi.formato.indexOf("%") !== -1 ? (props.row[kpi.sigla] * 100).toFixed( kpi.formato.replace("%", "") ) + "%" : props.row[kpi.sigla].toFixed(kpi.formato))+" ")])})]}},{key:"detail",fn:function(props){return [(_vm.renderTableDetail)?_c('table',[_c('thead',[_c('th',{staticStyle:{"text-align":"left","padding-left":"5px"}}),_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("Nome")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                                  content: card.scout.nome,
                                  classes: 'home-tooltip',
                                }),expression:"{\n                                  content: card.scout.nome,\n                                  classes: 'home-tooltip',\n                                }",modifiers:{"bottom":true}}],staticClass:"has-text-centered"},[_vm._v(" "+_vm._s(card.scout.sigla)+" ")]),_c('th',{staticClass:"has-text-centered"},[_vm._v("Média")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                                  content: 'Participação nos jogos do clube',
                                  classes: 'home-tooltip',
                                }),expression:"{\n                                  content: 'Participação nos jogos do clube',\n                                  classes: 'home-tooltip',\n                                }",modifiers:{"bottom":true}}],staticClass:"has-text-centered"},[_vm._v(" Jogos ")])]),_c('tbody',_vm._l((props.row.dataAcord),function(data,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"text-transform":"uppercase","font-weight":"bolder","text-align":"left","font-size":"0.6rem"}},[_vm._v(" "+_vm._s(data.atleta_pos)+" ")]),_c('td',{staticClass:"td-acord",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(data.atleta_nome)+" ")]),_c('td',{staticClass:"td-acord has-text-centered"},[_vm._v(" "+_vm._s(data.scout)+" ("+_vm._s(( (data.scout / props.row.scout) * 100 ).toFixed(1))+"%) ")]),_c('td',{staticClass:"td-acord has-text-centered"},[_vm._v(" "+_vm._s(( data.scout / data.partidas_atleta ).toFixed(1))+" ")]),_c('td',{staticClass:"td-acord has-text-centered"},[_vm._v(" "+_vm._s(data.partidas_atleta)+"/"+_vm._s(data.time_partidas)+" ")])])}),0)]):_c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered",staticStyle:{"font-size":"0.9rem"}},[_c('p',[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-medium"}})],1),_c('p',[_vm._v("Carregando...")])])])])]}}],null,true)}):_vm._e()],1)]):_vm._e()])}),1)],1):_vm._e()])])]),_c('b-modal',{attrs:{"active":_vm.showVodal,"has-modal-card":""},on:{"update:active":function($event){_vm.showVodal=$event}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Nova Coluna")])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":"Scout","type":_vm.error && typeof _vm.column.scout !== 'object'
                  ? 'is-danger'
                  : 'is-light',"message":_vm.error && typeof _vm.column.scout !== 'object'
                  ? 'Escolha um Scout'
                  : ''}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.column.scout),callback:function ($$v) {_vm.$set(_vm.column, "scout", $$v)},expression:"column.scout"}},_vm._l((_vm.arr_scouts_kpi),function(opt,index){return _c('option',{key:index,domProps:{"value":opt}},[_vm._v(_vm._s(opt.nome))])}),0)],1),_c('b-field',{attrs:{"label":"Mando de Jogo","type":_vm.error && _vm.column.mando == '' ? 'is-danger' : 'is-light',"message":_vm.error && _vm.column.mando == '' ? 'Escolha o Mando de Jogo' : ''}},[_c('div',[_c('b-switch',{attrs:{"true-value":"casa","name":"mando-vodal"},model:{value:(_vm.column.mando),callback:function ($$v) {_vm.$set(_vm.column, "mando", $$v)},expression:"column.mando"}},[_vm._v("Mandante")]),_c('b-switch',{attrs:{"true-value":"fora","name":"mando-vodal"},model:{value:(_vm.column.mando),callback:function ($$v) {_vm.$set(_vm.column, "mando", $$v)},expression:"column.mando"}},[_vm._v("Visitante")]),_c('b-switch',{attrs:{"true-value":"ambos","name":"mando-vodal"},model:{value:(_vm.column.mando),callback:function ($$v) {_vm.$set(_vm.column, "mando", $$v)},expression:"column.mando"}},[_vm._v("Ambos")])],1)]),_c('b-field',{attrs:{"label":"Filtro","type":_vm.error && _vm.column.filtro == '' ? 'is-danger' : 'is-light',"message":_vm.error && _vm.column.filtro == '' ? 'Escolha o Filtro' : ''}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.column.filtro),callback:function ($$v) {_vm.$set(_vm.column, "filtro", $$v)},expression:"column.filtro"}},_vm._l((_vm.arr_filters),function(opt){return _c('option',{key:opt,domProps:{"value":opt}},[_vm._v(_vm._s(opt))])}),0)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"type":"is-danger","outlined":""},on:{"click":function($event){_vm.showVodal = false}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"button is-success",on:{"click":function($event){return _vm.addColumn()}}},[_vm._v(" Adicionar ")])],1)])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }